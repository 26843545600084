import axios from "axios";
import GreyNav from "../Navbar/GreyNavbar/greyNav";
import infoImg from '../assets/infoImg.jpg'
import './texInfo.css'
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import DOMPurify from 'dompurify'; // Import dompurify


export default function TexInfo() {

    const [data, setData] = useState([])
    const { id } = useParams()

    const getProduct = async () => {
        const response = await axios.get(`https://api.u-ukm.uz/tex/${id}/`)
        setData(response.data)
    }

    useEffect(() => {
        getProduct()
    }, [])

    const { lang } = useContext(AppContext)


    return (
        <>
            {
                data.map(item => (
                    <>
                        <GreyNav />
                        <div className='App__nav__grey'>
                            <div className="App__nav__grey__box">
                                <h1>
                                    {lang == 'uz' ? item.title_uz : lang == 'ru' ? item.title_ru : item.title_en}
                                </h1>
                                <span></span>
                            </div>
                        </div>
                        <div className="texInfo">
                            <div className="texnikaINfo">
                                <div className="texnikaINfoImg">
                                    <img src={item.image} alt="" />
                                </div>
                                <div className="texnikDescription">
                                    <h4>{lang == 'uz' ? item.madel_uz : lang == 'ru' ? item.madel_ru : item.madel_en}</h4>
                                    <p
                                        key={item.id}
                                        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(lang == 'uz' ? item.desc_uz : lang == 'ru' ? item.desc_ru : item.desc_en) }}
                                    >
                                    </p>
                                    <button>zakaz</button>
                                </div>
                            </div>
                            <div className="texTable">
                                <table>
                                    {
                                        item.texnikala.map(item => (
                                            <tr>
                                                <td>{item.title}</td>
                                                <td>{item.kub}</td>
                                                <td>{item.sigim}</td>
                                            </tr>
                                        ))
                                    }
                                </table>
                            </div>
                        </div>
                    </>
                ))
            }
        </>
    )
}