import './Novosti.css';
import GreyNav from "../../Navbar/GreyNavbar/greyNav";
import { Link } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AppContext } from '../../context/AppContext';
import DOMPurify from 'dompurify'; // Import dompurify

export default function Novosti() {

    const [data, setData] = useState([])

    const getNews = async () => {
        const response = await axios.get("https://api.u-ukm.uz/yangilik")
        setData(response.data)
    }

    useEffect(() => {
        getNews()
    }, [])

    const { lang } = useContext(AppContext)

    return (
        <div className="Novosti">
            <GreyNav />
            <div className='App__nav__grey'>
                <div className="App__nav__grey__box">
                    <h1>
                        Новости
                    </h1>
                    <span></span>
                </div>
            </div>
            <div className="NovostiMain">
                <div className="NewsBox">
                    {
                        data.map(item => (
                            <div className="news">
                                <span>{item.date}</span>
                                <h1>{lang == 'uz' ? item.title_uz : lang == 'ru' ? item.title_ru : item.title_en}</h1>
                                <p key={item.id}
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(lang == 'uz' ? item.desc_uz : lang == 'ru' ? item.desc_ru : item.desc_en) }}></p>
                                <Link to={`/YangilikPage/${item.id}`}>
                                    <button>Batavsil</button>
                                </Link>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}