import './BatavsilKaryera.css';
import GreyNav from "../../../Navbar/GreyNavbar/greyNav";
import { Link } from 'react-router-dom';

export default function BatavsilKaryera() {
    return (
        <div className="Karyera">
            <GreyNav />
            <div className='App__nav__grey'>
                <div className="App__nav__grey__box">
                    <h1>
                        Batavsil
                    </h1>
                    <span></span>
                </div>
            </div>
            <div className="BatavsilMain">
                <div className="BatavsilMain__leftSide">
                    <div className="BatavsilMain__header">
                        <p>25.08.2023</p>
                        <h1>E`lon</h1>
                    </div>
                    <div className="BatavsilMain__content">
                        <p>
                        "O'zbekgeofizika" aksiyadorlik jamiyatining vakant lavozimlarga ishga qabul qilish, xodimlarni boshqa lavozimga o’tkazishda tanlov asosida saralab olish  tog’risidagi nizomga asosan 3D seysmik qidiruv partiyasi Texnik rahbari lavozimiga tanlovini e'lon qilinadi.

Tanlovda ishtirok etish uchun O'zbekiston Respublikasi fuqarolari, shuningdek ushbu tanlovida ishtirok etish istagini bildirgan xorijiy davlatlarning fuqarolari taklif etiladi.

Nomzodga qo'yiladigan talablar:

- oliy geologik va geofizik ma’lumotga hamda geologiya sohasida va mutaxassislik bo‘yicha muhandis-texnik lavozimlarida 5 yildan kam boʼlmagan ish tajribasiga ega bolishi;

- maxfiy hujjatlar bilan ishlash uchun ruxsatnomaga ega ekanligi yoki kelajakda mazkur ruxsatnoma rasmiylashtirilishiga to‘sqinlik qiluvchi salbiy holatlarning mavjud emasligi.

Tillarni bilishi:

Rus, o‘zbek  tillarini mukammal bilishi, ingliz tillini yaxshi bilishi.

Kompyuter bilimi:

MS Office- ishonch bilan foydalanish.

Xizmat vazifalari:

Ishlarni bajarish uchun berilgan topshiriqlarning to'g'ri bajarilishini, shuningdek ish joylarida MMQ, SX, TX va sanoat sanitariyasi qoidalariga rioya etilishini nazorat qiladi. To'g'ridan-to'g'ri ijrochilar (ishchilar, texnologik va ishlab chiqarish transport vositalari haydovchilari, vibrator operatorlari va boshqalar) tomonidan tarmoq suv ta'minoti tizimining 1-bosqichida xavfsiz mehnat sharoitlarini tezkor nazorat qilishni ta'minlaydi.
Xodimlarni xavfsiz ishlash bo'yicha o'z vaqtida o'qitishni, MMQ va TX bo'yicha qoidalar va ko'rsatmalarni o'rganishni ta'minlaydi.
"MMQ va SX kuni" ni o'tkazish bo'yicha komissiya ishida, shuningdek xavfsizlik qoidalarini bilish bo'yicha ishchi imtihonlarini qabul qilishda ishtirok etadi.
4.3D ishlarni bajarish uchun texnik loyihani va bajarilgan ishlar bo'yicha ishlab chiqarish hisobotini tayyorlashda ishtirok etadi 3D ishlarni bajarish uchun texnik loyihani va bajarilgan ishlar bo'yicha ishlab chiqarish hisobotini tayyorlashda ishtirok etadi.

3D ish paytida baxtsiz hodisalarning oldini olish bo'yicha texnik loyiha va jamoaviy shartnomada nazarda tutilgan tashkiliy tadbirlarning o'z vaqtida bajarilishini ta'minlaydi.
MMQ normalarining aniqlangan buzilishlarini, baxtsiz hodisalar sabablarini tahlil qilishda, ularni bartaraf etish va oldini olish bo'yicha chora-tadbirlarni ishlab chiqish va amalga oshirishda ishtirok etadi.
Dala otryad xodimlari bilan "O‘zbekgeofizika" AJ va yuqori turuvchi tashkilotlardan olingan buyruqlar va farmoyishlar, shuningdek axborot materiallari o'z vaqtida ishlab chiqilishini taminlaydi.
Dala geofizik materiallarini hisobga olish va har kuni qabul qilishni nazorat qiladi.
Mehnat sharoitlari:

Ish vaqti: vaxta uslubi, loyiha tugaguniga qadar muddatli.

O'zbekiston Respublikasi Mehnat kodeksiga muvofiq rasmiylashtiriladi.

Ish haqqi: shtat jadvali bilan belgilangan ish haqqi va Jamiyatning ichki hujjatlari va bozor mexanizmlariga muvofiq to'lovlarni (mukofotlar).

Tanlovda ishtirok etish uchun hujjatlarni topshirish tartibi:

Lavozimga talabgor bo‘lgan nomzodlarning hujjatlari (rezyume yoki ma'lumotnoma, diplomlar va guvohnomalar nusxalari, tavsiyanomalar, mehnat daftarchasi nusxasi, sudlanmaganligi to'g'risida guvohnoma) “O‘zbekgeofizika” aksiyadorlik jamiyatining xodimlarni boshqarish, tayyorlash va qayta tayyorlash bo‘liminiga qabul qilinadi.

Hujjatlarni qabul qilishning oxirgi muddati: 2023 yil 7 avgust soat 16-00 ga qadar.

Tanlov bo'yicha qo‘shimcha ma’lumotlar uchun ishtirokchilar 71 264-84-88 telefon raqami orqali bog'lanishlari mumkin.
                        </p>

                        <div className="Ariza">
                            <Link to={'/zyafka'}>
                            <a className='ArizaButton'  href="#*">Ariza koldirish</a>
                            </Link>
                        </div>
                    </div>
                </div>
                <aside className="aside">
                    <div className="aside__box">
                        <h1>Karyera</h1>
                        <li><a href="#*">Ish orinlari</a></li>
                        <li><a href="#*">Malaka oshirish</a></li>
                        <li><a href="#*">Yoshlar siyosati</a></li>
                    </div>
                </aside>
            </div>
        </div>
    )
}