import React, { useContext } from "react";
import "./Sob.css";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { AppContext } from "../../../context/AppContext";
import { Link } from "react-router-dom";

function Sob() {

  const getNews = async () => {
    const response = await axios.get("https://api.u-ukm.uz/yangilik/")
    setNews(response.data)
  }

  useEffect(() => {
    getNews()
  }, [])

  const [news, setNews] = useState([])

  const { lang } = useContext(AppContext)


  const refreshPage = () => {
    window.location.assign('/zapis');
  }

  return (
    <>
      <div id="Sob">
        <div className="Sob__nav">
          <h1>{lang == 'uz' ? "Voqealar" : lang == 'ru' ? "События" : "Events"}</h1>
          <span></span>
          <Link onClick={refreshPage}>{lang == 'uz' ? "Barcha voqealar" : lang == 'ru' ? "Все события" : "All events"}</Link>
        </div>
        <div className="Sob__container">
          <div className="Sob__box__contant">
            {
              news.map(item => (
                <Link onClick={() => window.location.assign(`/YangilikPage/${item.id}`)}>
                  <div className="Sob__box__img">
                    <img src={item.image} alt="" />
                    <div className="Sob__news1">
                      <p>{item.date}</p>
                      <h4>{lang === 'uz' ? item.title_uz : lang === 'ru' ? item.title_ru : item.title_en}</h4>
                    </div>
                  </div>

                </Link>
              ))
            }
            {/* <div className="Sob__box__img">
              <img src={img} alt="" />
              <div className="Sob__newss">
                <p>22.06.2023</p>
                <h4>Товары Корммаш увеличели мошьность!</h4>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Sob;
