import './TexPage.css'
import GreyNav from "../../Navbar/GreyNavbar/greyNav";
import Productsiya from '../Main/producsiya/Productsiya';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useState } from 'react';


export default function TexPage() {

    const { id } = useParams()
    const [texData, setTexData] = useState([])

    const getTex = async () => {
        const response = await axios.get(`https://api.u-ukm.uz/tex/${id}`)
        setTexData(response.data)
    }

    useEffect(() => {
        getTex()
    }, [])

    return (
        <>
            <GreyNav />
            <div className='App__nav__grey'>
                <div className="App__nav__grey__box">
                    <h1>
                        Техника для раздачи корма
                    </h1>
                    <span></span>
                </div>
            </div>
            <div className="TexPgageFlex">
                {
                    texData.map(item => (
                        <div className="TexPage">
                            <div className="TexPage__Left">
                                <img src={item.image} alt="" />
                            </div>
                            <div className="TexPage__Right">
                                <div className="TexPage__text">
                                    <h1>{item.madel_uz}</h1>
                                    <p>{item.title_uz}</p>
                                </div>
                                <div className="TexPage__button">
                                    {/* <button>Добавить в корзину</button> */}
                                    <Link to={`/texnikaInfo/${id}`}>
                                        <button>Подробнее</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))
                }
                <Productsiya />
            </div>
        </>
    )
}