import { useParams } from 'react-router-dom';
import './Rukmain.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import DOMPurify from 'dompurify'; // Import dompurify
import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';

function Rukmain() {
    const { id } = useParams();
    const [rukInfo, setRukInfo] = useState([]);

    const getInfo = async () => {
        try {
            const response = await axios.get(`https://api.u-ukm.uz/rax/${id}`);
            console.log(response.data);
            setRukInfo(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const {lang} = useContext(AppContext)

    useEffect(() => {
        getInfo();
    }, [id]);

    return (
        <div className="RukMain">
            {
                rukInfo.map(item => (
                    <div className="RukContant" key={item.id}>
                        <div className="RecContent__title">
                            <h1>
                                {item.title_uz}
                            </h1>
                            <h4>{item.lavozim_uz}</h4>
                        </div>
                        <div className="RukContent__box">
                            <div className="RukContent__Left">
                                <img src={item.image} alt="" />
                            </div>
                            <div className="RukContent__Right">
                                {
                                    item.raxbariyats.map(itemText => (
                                        <span
                                            key={itemText.id} 
                                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(lang == 'uz' ? itemText.desc_uz : lang == 'ru' ? itemText.desc_ru : itemText.desc_en) }}
                                        ></span>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default Rukmain;