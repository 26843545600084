import NavOb from "../../Navbar/NavOb";
import Rukmain from "./../RukMain/Rukmain";

function RukPage() {
    return (
        <div className="RukHeder">
            <NavOb/>
            <main>
                <Rukmain />
            </main>
        </div>
    )
}

export default RukPage;