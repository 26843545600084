/* eslint-disable jsx-a11y/iframe-has-title */
import './contact.css'
import React from 'react';
// import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import vkIcon from './../../../img/icons/wk.svg'
import rtubeIcon from './../../../img/icons/rtube.svg'
import okIcon from './../../../img/icons/ok.svg'
import youtubeIcon from './../../../img/icons/youtube.svg'
import telegramIcon from './../../../img/icons/telegram.svg'
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../../context/AppContext';

export default function Contact() {

    const { lang } = useContext(AppContext)

    return (
        <div className='contact'>
            <iframe className='ymaps-layers-pane' src="https://yandex.ru/map-widget/v1/?um=constructor%3Ac3ecc95ecf1c989239c78db4d5db6d52a3ce47c94cb31646065547c53d79e950&amp;source=constructor" width="100%" height="400" frameborder="0">.</iframe>
            <div className="locationInfo">
                <div className="locationInfoLeft">
                    <span>{lang == 'uz' ? "ishonch telefoni" : lang == 'ru' ? "горочая линия" : "hotline"}</span>
                    <h1>+998622248969</h1>
                    <Link to={'/zapis'}>
                    <button>{lang == 'uz' ? "bizga yozing" : lang == 'ru' ? "напишите нам" : "write to us"}</button>
                    </Link>
                </div>
                <div className="locationInfoRight">
                    <a href="#*"><img src={vkIcon} alt=""/><span>VK</span></a>
                    <a href="#*"><img src={rtubeIcon} alt=""/><span>Rtube</span></a>
                    <a href="#*"><img src={okIcon} alt=""/><span>Ok</span></a>
                    <a href="#*"><img src={youtubeIcon} alt=""/><span>Youtube</span></a>
                    <a href="#*"><img src={telegramIcon} alt=""/><span>Telegram</span></a>
                </div>
            </div>
        </div>
    )
}