import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";


function Gde() {

    const { lang } = useContext(AppContext)

    const refreshPage = ()=>{
        window.location.assign('/gdekupit');
    }

    return (
        <div className="wrapper">
            <div className="accordion">
                {data.map((item, i) => (
                    <div className="item">
                        <div className="title">
                            <Link onClick={refreshPage}>
                                <h2> {lang == 'uz' ? item.name_uz : lang == 'ru' ? item.name_ru : item.name_en} </h2>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

const data = [
    {
        name_uz: 'Sotib olish',
        name_ru: 'Где купить' ,
        name_en: 'Purchase'
    },
]

export default Gde;