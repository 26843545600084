import Form from "./Main/Forms/Form";
import Main from "./Main/Main";
import Sob from "./Main/Sobitiya/Sob";
import Productsiya from "./Main/producsiya/Productsiya";
import NavOb from "./../Navbar/NavOb";

function Home() {
  return (
    <div className="App">
      <NavOb />
      <main className='main'>
        <Main />
        <Sob />
        <Productsiya />
        <Form />
      </main>
    </div>
  );
}

export default Home;
