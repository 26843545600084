import Burger from "./Burger/Burger";
import Navbar from "./Navbar";
import Slide from "./Swiper/Slide";
import './style.css';

function NavOb() {
    return (
        <>
        <div className='App__navbar'>
        <div className='App__nav__up'>
          <Navbar/>
        </div>
        <div className='App__nav__burg'>
          <Burger/>
        </div>
        <div className='App__nav__slide'>
          <Slide/>
        </div>
      </div>
        </>
    )
}
export default NavOb;