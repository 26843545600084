import React, { useContext } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/AppContext";


function Online() {

    const {lang} = useContext(AppContext)

    const refreshPage = ()=>{
        window.location.assign('/zapis');
    }

    return (
        <div className="wrapper">
            <div className="accordion">
                {data.map((item, i) => (
                    <div className="item">
                        <div className="title">
                            <Link onClick={refreshPage}>
                                <h2 id="zayh2"> {lang == 'uz' ? item.name_uz : lang == 'ru' ? item.name_ru : item.name_en } </h2>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

const data = [
    {
        name_uz: 'Ilova',
        name_ru:  'Связь' ,
        name_en: 'Сonnection'

    },
]

export default Online;