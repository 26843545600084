import './footer.css'
import logo from '../img/UKM_Logo_black copy.png'
import { Link } from 'react-router-dom'
import { useContext } from 'react';
import { AppContext } from '../context/AppContext';

export default function FootBut() {

    
    const refreshPage1 = ()=>{
        window.location.assign('/texnika');
    }
    const refreshPage2 = ()=>{
        window.location.assign('/raxbariyat/1');
    }
    const refreshPage3 = ()=>{
        window.location.assign('/about');
    }
    const refreshPage4 = ()=>{
        window.location.assign('/zyafka');
    }
    const refreshPage5 = ()=>{
        window.location.assign('/aksiyadorlar-va-investorlar/46');
    }
    const refreshPage6x = ()=>{
        window.location.assign('/gdekupit');
    }
    const refreshPage7 = ()=>{
        window.location.assign('/zyafka');
    }
    const refreshPage8 = ()=>{
        window.location.assign('/contact');
    }

    const { lang } = useContext(AppContext)

    return (
        <div className="footer">
            <div className="footerLeft">
                <img src={logo} alt="" />
                <button>{lang == 'uz' ? "Yangiliklarga obuna bo'ling" : lang == 'ru' ? "Подписаться на новости" : "subscribe to news"}</button>
            </div>
            <div className="footerRight">
                <div className="footerRight_top">
                    <ul>
                        <Link onClick={refreshPage1}><li>{lang == 'uz' ? "Texnika" : lang == 'ru' ? "Техника" : "Technique"}</li></Link>
                        <Link onClick={refreshPage2}><li>{lang == 'uz' ? "Boshqaruv" : lang == 'ru' ? "Руководство" : "Management"}</li></Link>
                    </ul>
                    <ul>
                        <Link onClick={refreshPage3}><li>{ lang == 'uz' ? "Kompaniya" : lang == 'ru' ? "Компания" : "Company"}</li></Link>
                        <Link onClick={refreshPage4}><li>{lang == 'uz' ? "Xizmat so'rovi" : lang == 'ru' ? "Заявка на сервис" : "Service Request"}</li></Link>
                    </ul>
                    <ul>
                        <Link onClick={refreshPage5}><li>{lang == 'uz' ? "AKSIADORLIK jamiyati" : lang == 'ru' ? "Акционерное общество" : "Joint-Stock Company"}</li></Link>
                        <Link onClick={refreshPage6x}><li>{lang == 'uz' ? "Qayerdan sotib olsam bo'ladi" : lang == 'ru' ? "Где купить" : "Where can I buy"}</li></Link>
                    </ul>
                    <ul>
                        <Link onClick={refreshPage7}><li>{lang == 'uz' ? "Ehtiyot qismlar va xizmat ko'rsatish" : lang == 'ru' ? "Запчасти и сервис" : "Spare parts and service"}</li></Link>
                        <Link onClick={refreshPage8}><li>{lang == 'uz' ? "Kontaktlar" :  lang == 'ru' ? "Контакты" : "Contacts"}</li></Link>
                    </ul>
                </div>
                <div className="footerRight_bottom">
                    <div>
                        <h5>UKM@kormmash.uz</h5>
                        <span>г. Ургенч, <br/>ул. Ханка, 26</span>
                    </div>
                    <span>Узбекистан Узб</span>
                </div>
            </div>
        </div>
    )
}