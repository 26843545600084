import { useState, useEffect } from 'react';
import axios from 'axios';
import './style.css';
import Gde from '../Buttons/GdeKup/Gde';
import Online from '../Buttons/Onlide/Online';
import Akcioneri from '../Buttons/Akcioneri/Akcioneri';

function Button() {
  const [isOpen, setIsOpen] = useState(null);
  const [data, setData] = useState([]);

  const getCategory = async () => {
    try {
      const response = await axios.get('https://api.u-ukm.uz/categories/');
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <div className='nav__button__box'>
      <div className='navbar'>
        {data.map(item => (
          <div className='button' key={item.id}>
            <Akcioneri data={item} isOpen={isOpen} setIsOpen={setIsOpen} />
          </div>
        ))}
        <Online />
        <Gde />
      </div>
    </div>
  );
}

export default Button;
