import GreyNav from "../../Navbar/GreyNavbar/greyNav";
import './gdekupit.css'

export default function GdeKupit() {
    return (
        <div className="gdeKupit">
            <GreyNav />
            <div className='App__nav__grey'>
                <div className="App__nav__grey__box">
                    <h1>
                        ГДЕ КУПИТЬ
                    </h1>
                    <span></span>
                </div>
            </div>
            <h3 className="locationTitle">Хоразм В. Урганч Ш.<br/>Ханка Ул. 26д.</h3>
        </div>
    )
}