import './Navbar/Navbar';
import './App.css';
import Footer from './footer/Footer';
import { BrowserRouter as Router, Route, Routes, } from 'react-router-dom';
import Home from './Pages/Home';
import RukPage from './Pages/RukovodstvoPage/RukPage';
import TexnikaMain from './Pages/TexnikaMain/TexnikaMain';
import Loader from './loader/Loader';
import TexPage from './Pages/TexnikaPage/TexPage';
import Ao from './Pages/AksianerObshestvo/Ao';
import AboutPage from './Pages/AboutUsPage/AboutPage';
import { AppContext } from './context/AppContext';
import { useState } from 'react';
import GdeKupit from './Pages/gdeKupit/GdeKupit';
import Zyafka from './Pages/Zyafka/Zyafka';
import Sivyaz from './Pages/sivyaz/Sivyaz';
import Karyera from './Pages/Karyera/Karyera';
import CIcon from '@coreui/icons-react';
import * as icon from '@coreui/icons';
import BatavsilKaryera from './Pages/Karyera/BatavsilKaryera/BatavsilKaryera';
import Contact from './Pages/Main/contact/Contact';
import ContactPage from './Pages/CotactPage/ContactPage';
import axios from 'axios';
import Novosti from './Pages/Novosti/Novosti';
import Productsiya from './Pages/Main/producsiya/Productsiya';
import NovostiPage from './Pages/NovostiPage/NovostiPage';
import TexInfo from './texnikaInfo/TexInfo';

function App() {
  // loader
  const [onLoader, setOnLoader] = useState(true);

  setTimeout(() => {
    setOnLoader(false);
  }, 2000);

  // language selector
  const [lang, setLang] = useState('uz');

  // font set 

  // font size
  const [font, setFont] = useState(18);


  if (onLoader === true) {
    return <Loader />;
  }

  return (
    <AppContext.Provider value={{ lang, setLang, font, setFont }}>
      <div className="App">
        <Router>
          <div id='.' className='ghost'></div>
          <a className='swipe__up__button' href='#.'>
            <div className='swipe__up'>
              <CIcon icon={icon.cilCaretTop} size="md" />
            </div>
          </a>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/raxbariyat/:id' element={<RukPage />} />
            <Route path='/texnika' element={<TexnikaMain />} />
            <Route path='/texnika/:id' element={<TexPage />} />
            <Route path='/aksiyadorlar-va-investorlar/:id' element={<Ao />} />
            <Route path='/about' element={<AboutPage />} />
            <Route path='/gdekupit' element={<GdeKupit />} />
            <Route path='/zyafka' element={<Zyafka />} />
            <Route path='/zapis' element={<Sivyaz />} />
            {/* <Route path='/karyera' element={<Karyera />} /> */}
            <Route path='/Batavsil' element={<BatavsilKaryera />} />
            <Route path='/contact' element={<ContactPage />} />
            <Route path='/news' element={<Novosti />} />
            <Route path='/productsiya' element={<Productsiya />} />
            <Route path='/texnikaInfo/:id' element={<TexInfo />} />
            <Route path='/YangilikPage/:id' element={<NovostiPage/>}/>
            <Route path='/texnikaInfo' element={<TexInfo />} />
          </Routes>
          <footer>
            <Footer />
          </footer>
        </Router>
      </div>
    </AppContext.Provider>
  );
}

export default App;
