import React, { useContext } from "react";
import { useState } from "react";
import './Akcioneri.css';
import { AppContext } from "../../context/AppContext";
import { Link } from "react-router-dom";


function Akcioneri({ data, children, item, isOpen, setIsOpen }) {
    const [selected, setSelected] = useState(null)

    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null)
        }

        setSelected(i)
    }

    const { lang } = useContext(AppContext)

    // const  refreshPage = (children)=>{
    //     window.location.reload(`/${item.yordamchi}/${chilItem.id}`);
    // }

    return (
        <div className="wrapper">
            <div className="accordion">
                {/* {data.map((item, i) => ( */}
                <div className="item">
                    <div className="title" onClick={() => isOpen !== data.id ? setIsOpen(data.id) : setIsOpen(null)}>
                        <h2>{lang == 'uz' ? data.name_uz : lang == 'ru' ? data.name_ru : data.name_en}</h2>
                    </div>
                    <div className={isOpen === data.id ? 'akcioner show' : 'akcioner'}>

                        <div className="content__text1">
                            {
                                data.children.map(item => (
                                    <div className="box__b">
                                        <h1>{lang == 'uz' ? item.name_uz : lang == 'ru' ? item.name_ru : item.name_en}</h1>
                                        {
                                            item.children.map(chilItem => (
                                                <Link onClick={() => window.location.assign(`/${item.yordamchi}/${chilItem.id}`)}>
                                                    {lang === 'uz' ? chilItem.name_uz : lang === 'ru' ? chilItem.name_ru : chilItem.name_en}
                                                </Link>
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
                {/* ))} */}
            </div>
        </div>
    );
}

const data = [
    {


    },
]

export default Akcioneri;