import GreyNav from "../../Navbar/GreyNavbar/greyNav";
import './NovostiPage.css';
import img from './../../img/img1.jpg';
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import DOMPurify from "dompurify";

export default function NovostiPage() {

    const { id } = useParams()
    const [data, setData] = useState([])

    const getNews = async () => {
        const response = await axios.get(`https://api.u-ukm.uz/yangilik/${id}`)
        setData(response.data)
    }

    useEffect(() => {
        getNews()
    }, [])

    const { lang } = useContext(AppContext)

    return (
        <>
            <GreyNav />
            <div className='App__nav__grey'>
                <div className="App__nav__grey__box">
                    <h1>
                        {lang == 'uz' ? data.title_uz : lang == 'ru' ? data.title_ru : data.title_en}
                    </h1>
                    <span></span>
                </div>
            </div>
            <div className="NovostiPage">
                <div className="NovPageUp">
                    <div className="NavPageImage">
                        <img src={data.image} alt="img" />
                    </div>
                    <div className="NavPageText">
                        <p key={data.id}
                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(lang == 'uz' ? data.desc_uz : lang == 'ru' ? data.desc_ru : data.desc_en) }}></p>
                    </div>
                </div>
            </div>
            <div className="NovPageBtn">
                <Link to={'/news'}>
                    <button>
                        Назад
                    </button>
                </Link>
            </div>
        </>
    )
}