import GreyNav from '../../Navbar/GreyNavbar/greyNav';
import './ContactPage.css';

export default function ContactPage() {
    return (
        <div className="ContactPage">
            <GreyNav/>
            <div className='App__nav__grey'>
                <div className="App__nav__grey__box">
                    <h1>
                        Kontaktlar
                    </h1>
                    <span></span>
                </div>
            </div>
        </div>
    )
}