import './greyNav.css';
import Burger from "../Burger/Burger";
import Navbar from "../Navbar";

export default function GreyNav() {
    return (
        <div className='GreyApp'>
            <div className='App__navbar'>
                <div className='App__nav__up'>
                    <Navbar />
                </div>
                <div className='App__nav__burg'>
                    <Burger/>
                </div>
                {/* <div className='App__nav__grey'>
                <div className="App__nav__grey__box">
                    <h1>
                        Техника
                    </h1>
                    <span></span>
                </div>
            </div> */}
            </div>
        </div>
    )
}