import Osnovatel from './../../img/Снимок.jpg'
import './AboutPage.css';
import NavOb from "../../Navbar/NavOb";
import zav1 from './../../img/zavod1.jpeg';
import zav2 from './../../img/zavod2.jpeg';


export default function AboutPage() {
    return (
        <>
            <NavOb />
            <div className="AbutPage">
                <div className="Osnovatel">
                    <div className="OsnovatelTitle">
                        <h1>Атажонов Атавай Атажонович</h1>
                        <h4>Основатель</h4>
                    </div>
                    <div className="OsnovatelBoxGlav">
                        <div className="OsnovatelLeftBox">
                            <div className="OsnovatelBoxOne">
                                <span></span>
                                <p>
                                    Родился в 1949 году, Шаватском районе
                                    Хорезмской области. В 1973 году окончил Ташкентский Политехнический Институт. по специальности инженер-механик. В 1989 году вступил на должность директора
                                    «Ургенчкорммаш», которую занимал до 2014 года. С 2014 года и до последних дней своей жизни он являлся советником
                                    «АО Ургенчкорммаш».
                                </p>
                            </div>
                            <div className="OsnovatelBoxSecond">
                                <span></span>
                                <p>
                                    Атавай Атажонович был дальновидным, справедливым и внимательным руководителем. Во многом именно благодаря ему была запущена основная производственная часть завода, которая послужила трамплином для того чтобы на сегодняшний день «Ургенчкорммаш» стал узнаваемым и конкурентноспособным предприятием на международном рынке.
                                </p>
                            </div>
                        </div>
                        <div className="OsnovatelRightBox">
                            <img src={Osnovatel} alt="" />
                        </div>
                    </div>
                </div>
                <div className='AboutPageGlav'>
                    <div className="AboutPage__title">
                        <h1>
                            АО "Урганчкорммаш"
                        </h1>
                        <h4>
                            Краткая информация
                        </h4>
                    </div>
                    <div className="AboutBox">
                        <div className="AboutLeftBox">
                            <p>
                                Ургенчский завод кормоуборочных машин был организован в сентябре 1981 года на базе Любецкого ПО "завода им.Ухтомского" г.Люберцы Московской области.
                            </p>
                        </div>
                        <span></span>
                        <div className="AboutRightBox">
                            <p>
                                Занимаемая площадь - 7.46 га. В 1987 году введены в эксплуатацию новые производственные корпуса с новым оборудованием, производственная площадь которых состовляет 16160 кв.м.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="zavodGlav">
                    <div className="Zavod__title">
                        <h1>
                            О заводе
                        </h1>
                    </div>
                    <div className="zavod">
                        <div className="ZavodRight">
                            <div className="ZavodImage">
                                <img src={zav1} alt="" />
                                <img src={zav2} alt="" />
                            </div>
                        </div>
                        <div className="ZavodLeft">
                            <div className="ZavodText">
                                <div className='Text_zav'>
                                    <span></span>
                                    <p>
                                    На заводе имеются кузнечно-прессовый, механический, сварочный,инструментальный, и сборочные участки. На эксплуатации находятся прессы гидравлические, кривошипные, винторезные и гидропрессы усилием до 500тонн, токарные, фрезерные, сверлильные, гайконарезные, шлифовальные станки разной модификации, сварочные оборудования, компрессоры а также все участки оборудованы подъемно-крановыми механизмами.
                                    </p>
                                </div>
                                <div className='Text_zav'>
                                    <span></span>
                                    <p>
                                    Завод был специализирован на производство Косилки двух брусной КДП-4.0, с производственной мощностью до 4000 косилок в год.
                                    </p>
                                </div>
                                <div className='Text_zav'>
                                    <span></span>
                                    <p>
                                    После невосимости Республики Узбекистан завод начал самостоятельно работать над осваиванием новых видео сельско-хозяйственной техники. В результате чего завод стал производить Плуги необоротные разного вида, Косилки, Культиваторы.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Mission">
                    <div className="MissionTitle">
                        <h1>Миссия</h1>
                    </div>
                    <div className="MissionText">
                        <p>Производство и реализация сельсуохозяйственной техники, доступных широкому кругу потребления</p>
                    </div>
                </div>

                <div className="Cennosti">
                    <div className="CennostiTitle">
                        <h1>
                            Ценности
                        </h1>
                    </div>
                    <div className="ConnostiGlavBox">
                        <div className="CennostiBox">
                            <h2>Готовность к переменам</h2>
                            <p>Совершенствовать производство, внедрять самые современные технологии, повышать профессиональный уровень сотрудников.</p>
                        </div>
                        <div className="CennostiBox">
                            <h2>Вовлеченность</h2>
                            <p>UKM стремится к тому, чтобы все сотрудники испытывали гордость и удовлетворение от достигаемых нами результатов.</p>
                        </div>
                        <div className="CennostiBox">
                            <h2>Клиенто-ориентированность</h2>
                            <p>Умение слушать и понимать запросы наших клиентов является ключевым фактом в разработаке иннавационных решений.</p>
                        </div>
                        <div className="CennostiBox">
                            <h2>Преданность, открытость и честность</h2>
                            <p>Мы привержены своим ценностям и преданы нашим клиентам. Мы работаем честно и открыто как друг с другом, так и с нашими клиентами и партнерами</p>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}