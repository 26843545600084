import './footer.css'
import FootBut from './FootBut'
import Contact from './../Pages/Main/contact/Contact'

export default function Footer() {
    return (
        <div className="footerApp">
            <Contact/>
            <FootBut/>
        </div>
    )
}