import React, { useEffect, useState, useContext } from 'react';
import { HiOutlineDocumentCheck } from 'react-icons/hi2';
import './ao.css';
import GreyNav from '../../Navbar/GreyNavbar/greyNav';
import { AppContext } from '../../context/AppContext';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export default function Ao() {

  const { font } = useContext(AppContext);

  const { id } = useParams();
  const [aoItem, setAoItem] = useState([]);

  const getItem = async () => {
    try {
      const response = await axios.get(`https://api.u-ukm.uz/cat/${id}`);
      setAoItem(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getItem();
  }, [id]);

  const handlePNGFileOpen = (fileUrl) => {
    const pngWindow = window.open(fileUrl, '_blank');
    pngWindow.focus();
  };

  return (
    <div className="ao">
      <div className="nav">
        <GreyNav />
        <div className="App__nav__grey">
          <div className="App__nav__grey__box">
            <h1>Акционерное общество</h1>
            <span></span>
          </div>
        </div>
      </div>
      <div className="aoMain">
        {/* <button onClick={handleFileDownload}>
          <div>
            <HiOutlineDocumentCheck size={'60px'} />
            <p>КРАТКАЯ ИНФОРМАЦИЯ ОБ ОРГАНИЗАЦИИ И<br /> ДЕЯТЕЛЬНОСТИ АО УРГЕНЧКОРММАШ</p>
          </div>
        </button> */}
        <table>
          <thead>
            <tr>
              <th>Заголовок</th>
            </tr>
          </thead>
          <tbody>
            {aoItem.map((item, index) => (
              <tr key={index}>
                <td style={{ fontSize: `${font}px`, cursor: 'pointer' }} onClick={() => handlePNGFileOpen(item.file)}>
                  {item.title_uz}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
