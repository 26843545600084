import './productsiya.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import texnika1 from './../../../img/texnika1.png'
import texnika2 from './../../../img/texnika2.png'
import texnika3 from './../../../img/texnika3.png'


// import required modules
import { Autoplay, } from 'swiper/modules';
import 'swiper/css';
import { Link, NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useContext } from 'react';
import { AppContext } from '../../../context/AppContext';

export default function Productsiya() {
    const activeLink = 'nav-list__link nav-list__link--active';
    const normLink = 'nav-list__link';

    const [texniks, setTexniks] = useState([])

    const getTexniks = async () => {
        const response = await axios.get("https://api.u-ukm.uz/texnika/")
        setTexniks(response.data)
    }

    useEffect(() => {
        getTexniks()
    }, [])

    const { lang } = useContext(AppContext)

    return (
        <div className="producsiya">
            <div className="producsiyaHeader">
                <h1>{lang == 'uz' ? "Mahsulotlar" : lang == 'ru' ? "Продукция" : "Products"}</h1>
                <div></div>
                <Link onClick={() => window.location.assign(`/texnika`)}><button>{lang == 'uz' ? "Barcha mahsulotlar" : lang == 'ru' ? "Все продукты" : "All products"}</button></Link>
            </div>
            <div className="producsiyaSwip">
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    loop={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay]}
                    className="mySwiper1"
                >
                    {
                        texniks.map(item => (
                            <SwiperSlide id='slider'>
                                <img className='texnikaImg' src={item.image} alt="" />
                                <div className='box'>
                                    <h1>{item.title_uz}</h1>
                                    <div></div>
                                    <NavLink onClick={() => window.location.assign(`/texnika/${item.category}`)} className={({ isActive }) => isActive ? activeLink : normLink}>
                                        <button >подробнее</button>
                                    </NavLink>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </div>
    )
}