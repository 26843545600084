import './zyafka.css'
import GreyNav from '../../Navbar/GreyNavbar/greyNav'

export default function Zyafka() {
    return (
        <div className="zyafka">
            <GreyNav />
            <div className='App__nav__grey'>
                <div className="App__nav__grey__box">
                    <h1>
                        Заявка на сервис
                    </h1>
                    <span></span>
                </div>
            </div>
            <div className="form">
                <div className="formHeader">
                    <h1>Заявка</h1>
                </div>
                <form action="">
                    <div className="text">
                        <textarea placeholder="введите текст:" name="" id="" cols="30" rows="10"></textarea>
                        <input placeholder="введите email:" type="email" />
                        <input placeholder="введите имя:" type="text" />
                        <input placeholder="введите номер:" type="text" />
                        <input placeholder="введите фамилию:" type="text" />
                    </div>
                    <button type='submit'>отправить</button>
                </form>
            </div>
        </div>
    )
}