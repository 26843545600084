import './Main.css';
import tractor from '../../img/tarktor (2).png';
import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';

function Main() {

    const {lang , font} = useContext(AppContext)

    return (
        <div className="About__us">
            <div className="About__img">
                <img src={tractor} alt="img"/>
            </div>
            <span></span>
            <div className="About__text">
                <h1>{lang === 'uz' ? "Tanishuv" : lang === 'ru' ? "Знакомство" : "Dating"}</h1>
                <h3>{lang == 'uz' ? "Sifatli parvarish" : lang == 'ru' ? "Забота о качестве" : "Quality care"}</h3>
                <p style={{ fontSize: `${font}px` }}>
                    {lang == 'uz' ? "UKM keng turdagi mahsulotlarni taklif etadi har bir mijozning ehtiyojlariga moslashtirilgan. Biz bunga g'amxo'rlik qilamiz mahsulotlarimiz ishonchli va samarali bo'lgan ularni yaratishning butun jarayoni - dizayndan yakuniygacha sifatni ta'minlash protseduralari. Biz ulardan biri bo'lganimizdan mamnunmiz sohadagi eng mashhur kompaniyalar. uchun biz bilan bog'laning qanday imtiyozlar taklif qilayotganimizni bilib oling." : lang == 'ru' ? "«UKM» предлагает широкий ассортимент продукции с учетом нужд каждого клиента. Мы заботимся о том, чтобы наши товары были надежными и эффективными, на протяжении всего процесса их создания — от проектирования до итоговых процедур обеспечения качества. Нам приятно быть одной из самых известных компаний в отрасли. Свяжитесь с нами, чтобы узнать, какие преимущества мы предлагаем." : "UKM offers a corporate assortment of products uchetom emergency kajdogo client. My zabotimsya o tom, chtoby nashi tovary byli nadejnymi i effektivnymi, na protyajenii vsego protsessa ix sozdaniya — ot proektirovaniya do itogovyx procedure obespecheniya kachestva. Nam priyatno byt odnoy iz the same well-known company and other companies. Svyajites s nami, chtoby uznat, kakie preimushchestva my predlagaem."}
                </p>
            </div>
        </div>
    )
}

export default Main;