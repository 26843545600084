import React from "react";
import './style.css';
import logo from './../img/UKM_Logo_black copy.png';
import logo2 from './../img/shopping-cart-icon-vector-20209336 copy (2).png';
import Button from "./Button";
import { NavLink } from "react-router-dom";
import { useContext, useState } from "react";
import { AppContext } from "../context/AppContext";
import { useEffect } from "react";
import App from "../App";

function Navbar() {
    const activeLink = 'nav-list__link nav-list__link--active';
    const normLink = 'nav-list__link';

    const { lang, setLang } = useContext(AppContext)

    useEffect(() => {
        const storedLang = localStorage.getItem('lang');
        if (storedLang) {
            setLang(storedLang);
        }
    }, []); // Run only once on component mount

    const handleLangChange = (newLang) => {
        setLang(newLang);
        localStorage.setItem('lang', newLang);
    }

    // font set

    const [fontValue , setFontValue] = useState(null)

    function Plus() {
        setFont(font + 2);
        setFontValue(font.value)
        if (font > 28) {
            setFont()
        }
    }
    function returnFont() {
        setFont(18);
    }
    function Minus() {
        setFont(font - 2);
        if (font == 12) {
            setFont(18)
        }
    }

    const { font, setFont } = useContext(AppContext)

    // fixed func
    const [navClass, setNavClass] = useState('Navbar__Container');

    function FixedNav() {
        const scrollY = window.scrollY; // Scroll y positionni hozir funksiya ichida olishimiz kerak
        if (scrollY > 200) {
            setNavClass('Navbar__Container fixed');
        } else {
            setNavClass('Navbar__Container'); // Scroll 200 dan kam bo'lsa fixed klassini olib tashlash
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', FixedNav); // scroll hodisasini eshitamiz
        return () => {
            window.removeEventListener('scroll', FixedNav); // komponent ochirilganda eshitamizni to'xtatamiz
        };
    }, []);

    const refreshPageNews = () => {
        window.location.assign('/news');
    }

    const refreshPageZyafka = () => {
        window.location.assign('/zyafka');
    }

    const refreshPageKompany = () => {
        window.location.assign('/about');
    }

    const refreshPageKaryera = () => {
        window.location.assign('/karyera');
    }

    const refreshPageKontact = () => {
        window.location.assign('/contact');
    }

    const refreshPageHome = () => {
        window.location.assign('/');
    }


    return (
        <div className={navClass}>
            <header className="Navbar__up">
                <div className="language__buttons">
                    <button onClick={() => handleLangChange('uz')}>uz</button>
                    <p>\</p>
                    <button onClick={() => handleLangChange('ru')}>ru</button>
                    <p>\</p>
                    <button onClick={() => handleLangChange('en')}>en</button>
                </div>
                <div className="fontChaneger">
                    <button onClick={Minus}>A-</button>
                    <button onClick={returnFont}>A</button>
                    <button onClick={Plus}>A+</button>
                </div>
                <div className="first__menu__buttons">
                    <ul >
                        <NavLink onClick={refreshPageNews} className={({ isActive }) => isActive ? activeLink : normLink}>
                            <li><button>{lang == 'uz' ? "Yangiliklar" : lang == 'ru' ? "Новости" : "News"}</button></li>
                        </NavLink>
                        <NavLink onClick={refreshPageZyafka} className={({ isActive }) => isActive ? activeLink : normLink}>
                            <li><button>{lang == 'uz' ? "Xizmat so'rovi" : lang == 'ru' ? "Заявка на сервис" : "Service request"}</button></li>
                        </NavLink>
                        <NavLink onClick={refreshPageKompany} className={({ isActive }) => isActive ? activeLink : normLink}>
                            <li><button>{lang == 'uz' ? "Kompaniya haqida" : lang == 'ru' ? "о компании" : "About the company"}</button></li>
                        </NavLink>
                        {/* <NavLink onClick={refreshPageKaryera} className={({ isActive }) => isActive ? activeLink : normLink}>
                            <li><button>{lang == 'uz' ? "martaba" : lang == 'ru' ? "карьера" : "career"}</button></li>
                        </NavLink> */}
                        <NavLink onClick={refreshPageKontact} className={({ isActive }) => isActive ? activeLink : normLink}>
                            <li><button>{lang == 'uz' ? "kontaktlar" : lang == 'ru' ? "контакты" : "contacts"}</button></li>
                        </NavLink>
                    </ul>
                </div>
            </header>
            <header className="Navbar__duwn">
                <div className="Navbar__logo">
                    <NavLink onClick={refreshPageHome} className={({ isActive }) => isActive ? activeLink : normLink}>
                        <img src={logo} alt="logo" />
                    </NavLink>
                </div>
                <div className="Second__menu__buttons">
                    <div className="Navbar__logo2">
                        <button>
                            <img src={logo2} alt="" />
                        </button>
                    </div>
                    <Button />
                </div>
            </header>
        </div>
    );
}

export default Navbar;