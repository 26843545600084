import texnika1 from '../../img/texnika1.png'
import texnika2 from '../../img/texnika2.png'
import texnika3 from '../../img/texnika3.png'
import texnika4 from '../../img/texnik4.png'
import './texnikamain.css'
import { NavLink } from 'react-router-dom'
import GreyNav from '../../Navbar/GreyNavbar/greyNav'
import { useState } from 'react'
import axios from 'axios'
import { useEffect } from 'react'

export default function TexnikaMain() {

    const activeLink = 'nav-list__link nav-list__link--active';
    const normLink = 'nav-list__link';

    const [data , setData] = useState([])


    const getTexniks = async () => {
        const response = await axios.get("https://api.u-ukm.uz/texnika/")
        setData(response.data)
    }

    useEffect(() => {
        getTexniks()
    }, [])

    return (
        <div className="AppTexMain">
            <GreyNav />
            <div className='App__nav__grey'>
                <div className="App__nav__grey__box">
                    <h1>
                        Техника
                    </h1>
                    <span></span>
                </div>
            </div>
            <div className="texnikaMian">
                <div className="texnikaTitle">
                </div>
                <div className="texniks">
                    {
                        data.map(item => (
                            <div className="texnikaCard">
                                <div className="texnikaLeft">
                                    <img src={item.image} alt="" />
                                </div>
                                <div className="texnikaRight">
                                    <span>{item.title_uz}</span>
                                    <div></div>
                                    <NavLink to={`/texnika/${item.category}`} className={({ isActive }) => isActive ? activeLink : normLink}>
                                        <button>Подробнее</button>
                                    </NavLink>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}