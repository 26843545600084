import { Link } from 'react-router-dom'
import './form.css'
import { useContext } from 'react';
import { AppContext } from '../../../context/AppContext';

export default function Form() {

    
    const refreshPage = ()=>{
        window.location.assign('/zapis');
    }
    
    const { lang } = useContext(AppContext)

    return (
        <div className="form">
            <div className="formHeader">
                <h1>{lang == 'uz' ? "Ulanish" : lang == 'ru' ? "Связь" : "Connection"}</h1>
                <div></div>
                <Link onClick={refreshPage}><button>{lang == 'uz' ? "BIZ BILAN BOG'LANISH" : lang == 'ru' ? "СВЯЗАТСЯ С НАМИ" : "CONTACT US"}</button></Link>
            </div>
            <form action="">
                <div className="text">
                    <textarea placeholder={lang == 'uz' ? "matn kiriting:" : lang == 'ru' ? "введите текст:" : "enter text:"} name="" id="" cols="30" rows="10"></textarea>
                    <input placeholder={lang == 'uz' ? "elektron pochtani kiriting:" : lang == 'ru' ? "введите email:" : "enter email:"} type="email" />
                    <input placeholder={lang == 'uz' ? "Ismingizni kiriting:" : lang == 'ru' ? "введите имя:" : "Enter your name:"} type="text" />
                    <input placeholder={lang == 'uz' ? "raqamni kiriting:" : lang == 'ru' ? "введите номер:" : "enter the number:"} type="text" />
                    <input placeholder={lang == 'uz' ? "familiyani kiriting" : lang == 'ru' ? "введите фамилию:" : "enter last name"} type="text" />
                </div>
                <button type='submit'>{lang == 'uz' ? "yuborish" : lang == 'ru' ? "отправить" : "to send"}</button>
            </form>
        </div>
    )
}