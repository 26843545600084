import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import './Slide.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import axios from 'axios';




function Slide() {

  const { lang } = useContext(AppContext)

  // api 

  const [data, setData] = useState([])

  const getSwiper = async () => {
    const response = await axios.get("https://api.u-ukm.uz/banners/")
    console.log(response.data);
    setData(response.data)
  }

  useEffect(() => {
    getSwiper()
  }, [])

  return (
    <Swiper className="Swiper"
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 10000,
        disableOnInteraction: false,
      }}
      loop={true}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
    >
      {
        data.map(item => (
          <SwiperSlide>
            <div className='Slider__box'>
              <div className='Slider__img'>
                <img src={item.image} alt='img1' />
              </div>
              <div className='Slider__text'>
                <p>
                  {
                    lang === 'uz' ?  item.name_uz :
                      lang === 'ru' ? item.name_ru :
                      item.name_en
                  }
                </p>
                <h1>
                  {
                    lang === 'uz' ? item.desc_uz :
                      lang === 'ru' ? item.desc_ru :
                      item.desc_en
                  }
                  <br />
                  {/* {
                    lang == 'uz' ? "508 mln.so‘m" :
                      lang == 'ru' ? "508 млн. сум" :
                        "508 million som"
                  } */}
                </h1>
              </div>
            </div>
          </SwiperSlide>
        ))
      }
    </Swiper>
  );
};

export default Slide;